import { OnInit, Component } from '@angular/core';

@Component({
  selector: 'app-lse',
  templateUrl: './lse.component.html',
  styleUrls: ['./lse.component.scss']
})
export class LseComponent implements OnInit {
  ngOnInit(): void {

  }
}
