export class GrammarPart {
    uuid: string;
    name: string;
    contentProviders: string[];
    editors: string[];
    creationDate: string;
    revisionDate: string;
    author: string;
    parts: any[];
    status: string;
    isDeleted: boolean;
    elementNumber: string;
    type: string;
    html : string;
    options: any;
  }