import { OnInit, Component } from '@angular/core';

@Component({
  selector: 'app-lsc',
  templateUrl: './lsc.component.html',
  styleUrls: ['./lsc.component.scss']
})
export class LscComponent implements OnInit {
  ngOnInit(): void {
  }
}
